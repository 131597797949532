<template>
  <span>
    <template v-if="tipo_pendencia === 'PendenciaImportacaoDados'">
      <span v-html="getImportacaoText"></span>
    </template>
    <template v-if="tipo_pendencia === 'PendenciaAprovacaoIndicadores'">
      {{ getIsDoneText }} {{ getStatusText }} a aprovação dos indicadores do
      tema <b>{{ getBaseName }} - {{ getCategoryName }}</b> da competência
      <b>{{
        notificationData.indger_version.data_month
          | parseToCustomDateFormat("MM/YYYY")
      }}</b>
    </template>
  </span>
</template>

<script>

export default {
  props: {
    tipo_pendencia: {
      type: String,
      required: true,
    },
    notificationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      //
    }
  },
  computed: {
    getIsDoneText() {
      return this.notificationData.done ? "Concluído" : "Pendente";
    },
    getStatusText() {
      return this.notificationData.status
        ? `(${this.notificationData.status})`
        : "";
    },
    getImportacaoText() {
      if (this.notificationData.tema == undefined) {
        return `${this.getIsDoneText} a Importação do ${
          this.notificationData.base
        } da competência <b> ${this.$options.filters.parseToCustomDateFormat(
          this.notificationData.data_month,
          "MM/YYYY"
        )} v${this.notificationData.version}</b>`;
      }

      return `${this.getIsDoneText} a retificação dos indicadores da base <b>${
        this.getBaseName
      } - ${
        this.getTemaName
      } </b> da competência <b> ${this.$options.filters.parseToCustomDateFormat(
        this.notificationData.data_month,
        "MM/YYYY"
      )} </b> `;
    },
    getBaseName() {
      const baseNames = {
        COMERCIAL: "Comercial",
        SERVICOS: "Serviços",
        TEC_AL: "Alimentadores",
        TEC_LD: "Linhas de Distribuição",
        TEC_SUB: "Subestações",
      };

      return baseNames[this.notificationData.base];
    },
    getCategoryName() {
      return (
        this.notificationData.category?.category ??
        this.notificationData.category?.nome
      );
    },
    getTemaName() {
    
      return (
        this.notificationData.tema?.category ??
        this.notificationData.tema?.nome
      );
    },
  },

  methods: {},
};
</script>

<style></style>